import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Pagination } from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import { normalizeCnpj } from '../utils/normalize'

import { format, parseISO } from "date-fns";

import { toast } from "react-toastify";

export default function UsersIndex() {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filials, setFilials] = useState([]);

  const [filialSelected, setFilialSelected] = useState(null);

  const [user, setUser] = useState({});

  useEffect(() => {
    async function loadFilials() {
      const { data } = await axios.get(
        "/e-rede-credencials?page=1&perPage=100000"
      );
      setFilials(data.data);
    }
    loadFilials();
  }, []);

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get("/me");
      setUser(data);
    }
    loadUser();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [total, setTotal] = useState(0);

  async function loadSellers() {
    setLoading(true);
    try {
      const response = await axios.get(
        `/sellers?page=${page + 1}&perPage=${rowsPerPage}&${filialSelected ? `e_rede_credencial_id=${filialSelected}` : ''}`
      );
      setRows(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadSellers();
  }, [page, rowsPerPage]); // eslint-disable-line

  function handleNextPage() {
    const nextPage = page + 1;
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1;
    setPage(previousPage);
  }

  const headRows = [
    { id: "code", numeric: false, disablePadding: true, label: "Código" },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Nome",
    },
    {
      id: "credencial",
      numeric: false,
      disablePadding: true,
      label: "Filial",
    },
    {
      id: "active",
      numeric: false,
      disablePadding: true,
      label: "Ativo",
    },
    {
      id: "created_at",
      numeric: false,
      disablePadding: true,
      label: "Data de Cadastro",
    },
    { id: "actions", numeric: false, disablePadding: true, label: "Ações" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy } = props;

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.numeric ? "right" : "left"}
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }
  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Vendedores</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/sellers`}>
                Lista
              </Link>
            </div>
          </div>

          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper d-flex">
            <form
                onSubmit={(e) => {
                  e.preventDefault();
                  loadSellers();
                }}
                className="d-flex"
                style={{ padding: "10px", background: "#eee" }}
              >
                <div style={{ width: "100%" }} className="d-flex flex-column">
                  <select
                    value={filialSelected}
                    style={{
                      border: "1px solid #c9c9c9",
                      padding: "2px 10px",
                      background: "#fff",
                    }}
                    onChange={(e) => setFilialSelected(e.target.value)}
                  >
                    <option value="">Todas as Lojas</option>
                    {user && user.branchs && user.branchs.length === 0
                      ? filials.map((filial) => (
                          <option value={filial.id}>
                            {filial.filial} - {normalizeCnpj(filial.cnpj)}
                          </option>
                        ))
                      : filials.map((filial) => (
                          <option
                            value={filial.id}
                            disabled={
                              !user?.branchs?.find(
                                (b) => b.e_rede_credencial_id === filial.id
                              )
                            }
                          >
                            {filial.filial} - {normalizeCnpj(filial.cnpj)}
                          </option>
                        ))}
                  </select>
                </div>
                <button
                  type="submit"
                  className="bg-white ml-2"
                  style={{
                    border: "none",
                    height: "44px !important",
                    padding: "0px 10px",
                  }}
                >
                  FILTRAR
                </button>
              </form>
              {user.roles && user.roles.find((i) => i.slug === "owner") && (
                <button
                  type="button"
                  onClick={() => {
                    history.push("/sellers/add");
                  }}
                  className="btn btn-danger"
                >
                  Cadastrar Vendedor
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows &&
                rows.length > 0 &&
                rows.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="left">{row.code}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.credencial?.filial} - {normalizeCnpj(row.credencial?.cnpj)}</TableCell>
                      <TableCell align="left"><strong>{row.active === 1 ? 'SIM' : 'NÃO'}</strong></TableCell>
                      <TableCell align="left">{row.created_at ? format(parseISO(row.created_at), 'dd/MM/yyyy HH:mm') : ''}</TableCell>
                      <TableCell align="right">
                        {user.roles &&
                          user.roles.find((i) => i.slug === "owner") && (
                            <button
                              type="button"
                              className="btn btn-sm pl-2 pr-2"
                              onClick={() => {
                                history.push(`/sellers/edit/${row.id}`);
                              }}
                            >
                              <EditIcon />
                            </button>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && <CircularProgress size={15} className="mr-2" />}
              <Pagination>
                {page > 0 && (
                  <>
                    <Pagination.First
                      onClick={() => setPage(0)}
                      disabled={page === 0 || loading}
                    />
                    <Pagination.Prev
                      onClick={() => handlePreviousPage()}
                      disabled={loading}
                    />
                  </>
                )}
                <Pagination.Item disabled>{page + 1}</Pagination.Item>
                {page < Math.ceil(total / rowsPerPage) - 1 && (
                  <>
                    <Pagination.Next
                      onClick={() => handleNextPage()}
                      disabled={loading}
                    />
                    <Pagination.Last
                      onClick={() =>
                        setPage(Math.ceil(total / rowsPerPage) - 1)
                      }
                      disabled={loading}
                    />
                  </>
                )}
              </Pagination>
            </div>
            <FormControl
              style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
              disabled={loading}
            >
              <InputLabel htmlFor="results_per_page">
                Result. p/Página
              </InputLabel>
              <Select
                id="results_per_page"
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
                disabled={loading}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}
