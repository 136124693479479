import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
  makeStyles,
  CircularProgress,
  Modal,
  Fade,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Pagination } from "react-bootstrap";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { format, parseISO } from 'date-fns';

import { toast } from 'react-toastify';

export default function UsersIndex() {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({});

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('/me');
      setUser(data);
    }
    loadUser();
  }, [])

  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '100%',
      maxWidth: '500px'
    }
  }));
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [deleteId, setDeleteId] = useState(null);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);

  async function loadUsers() {
    setLoading(true);
    try {
      const response = await axios.get(`/users?page=${page+1}&perPage=${rowsPerPage}`);
      setRows(response.data.data);
      setTotal(response.data.total);
    }catch(err) {
      if(err.response && err.response.status === 403) {
        toast.error('Você não tem permissão para este recurso');
      }else if(err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      }else if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
      }else{
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadUsers();
  }, []); // eslint-disable-line

  useEffect(() => {
    loadUsers();
  }, [page, rowsPerPage]); // eslint-disable-line 

  function handleNextPage() {
    const nextPage = page + 1
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1
    setPage(previousPage);
  }

  const headRows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'email', numeric: false, disablePadding: true, label: 'E-mail' },
    { id: 'role', numeric: false, disablePadding: true, label: 'Perfil' },
    { id: 'date', numeric: false, disablePadding: true, label: 'Data de Criação' },
    { id: 'actions', numeric: false, disablePadding: true, label: 'Ações' },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleDeleteModal(id) {
    setDeleteId(id);
    setOpen(true);
  }

  async function handleDelete() {
    try {
      await axios.delete(`/user/${deleteId}`);
      setOpen(false);
      setDeleteId(null);
      await loadUsers();
    }catch(err) {
      if(err.response && err.response.status === 403) {
        toast.error('Você não tem permissão para este recurso');
      }else if(err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      }else if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
      }else{
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }
  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Usuários
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/users`}>
                Lista
              </Link>
            </div>
          </div>

          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              {user.permissions && user.permissions.find(i => i === 'create-user') && (
                <button
                  type="button"
                  onClick={() => { history.push('/users/add') }}
                  className="btn btn-danger">
                  Cadastrar Novo Usuário
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => { setOpen(!open); setDeleteId(null) }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fade}>
            <h2 id="transition-modal-title">Confirmação</h2>
            <p id="transition-modal-description">Tem certeza que deseja excluir esta Filial?</p>
            <button type="button" className="btn btn-danger kt-subheader__btn-options" onClick={handleDelete}>Sim, excluir</button>
            <button type="button" className="btn btn-light kt-subheader__btn-options" onClick={() => { setOpen(false); setDeleteId(null) }}>Cancelar</button>
          </div>
        </Fade>
      </Modal>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
            {rows && rows.length > 0 && rows
                .map((row, index) => {

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.roles && row.roles.map(r => r.name).join(',')}</TableCell>
                      <TableCell align="left">{format(parseISO(row.created_at), 'dd/MM/yyyy')}</TableCell>
                      <TableCell align="right">
                        {user.permissions && user.permissions.find(i => i === 'update-product') && row.id !== user.id && (
                          <button
                            type="button"
                            className="btn btn-sm pl-2 pr-2"
                            onClick={() => {
                              history.push(`/users/edit/${row.id}`)
                            }}>
                            <EditIcon />
                          </button>
                        )}
                        {user.permissions && user.permissions.find(i => i === 'update-product') && row.id !== user.id && !row.roles.find(r => r.slug === 'owner') && (
                          <button
                            type="button"
                            className="btn btn-sm pl-2 pr-2"
                            onClick={() => handleDeleteModal(row.id)}>
                            <DeleteIcon />
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && (
                <CircularProgress size={15} className="mr-2" />
              )}
            <Pagination>
              {page > 0 && (
                <>
                  <Pagination.First onClick={() => setPage(0)} disabled={page === 0 || loading} />
                  <Pagination.Prev onClick={() => handlePreviousPage()} disabled={loading}/>
                </>
              )}
              <Pagination.Item disabled>{page + 1}</Pagination.Item>
              {page < (Math.ceil(total/rowsPerPage) - 1) && (
                <>
                <Pagination.Next onClick={() => handleNextPage()} disabled={loading}/>
                <Pagination.Last onClick={() => setPage(Math.ceil(total/rowsPerPage) - 1)} disabled={loading}/>
                </>
              )}
            </Pagination>
            </div>
            <FormControl
                style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
                disabled={loading}
              >
                <InputLabel htmlFor="results_per_page">Result. p/Página</InputLabel>
                <Select
                  id="results_per_page"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  disabled={loading}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}