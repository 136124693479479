import React, { useEffect, useState } from "react";

import axios from "axios";
import { normalizeCurrency } from "./utils/normalize";

import { Card, Form, Button, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { format, parseISO } from "date-fns";

export default function Dashboard() {
  const [products, setProducts] = useState([]);
  const [user, setUser] = useState({});

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('/me');
      setUser(data);
    }
    loadUser();
  }, [])

  const [orderStartDate, setOrderStartDate] = useState('');
  const [orderEndDate, setOrderEndDate] = useState('');

  const [orderPaymentLinkStartDate, setOrderPaymentLinkStartDate] = useState('');
  const [orderPaymentLinkEndDate, setOrderPaymentLinkEndDate] = useState('');

  const [orders, setOrders] = useState([]);
  const [links, setLinks] = useState([]);

  const [startDateNewOrders, setStartDateNewOrders] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDateNewOrders, setEndDateNewOrders] = useState(format(new Date(), 'yyyy-MM-dd'));

  const [startDateNewLinks, setStartDateNewLinks] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDateNewLinks, setEndDateNewLinks] = useState(format(new Date(), 'yyyy-MM-dd'));

  const { company_id } = useSelector((state) => state.auth.user);

  useEffect(() => {
    async function loadNewOrders() {
      const { data } = await axios.get(`/order-reports`, {
        params: {
          start_order_date: startDateNewOrders,
          end_order_date: endDateNewOrders,
          e_rede_credencial_id: user.e_rede_credencial_id ? user.e_rede_credencial_id : undefined 
        }
      })
      setOrders(data);
    }
    loadNewOrders();
  }, [startDateNewOrders, endDateNewOrders, user.e_rede_credencial_id]);

  useEffect(() => {
    async function loadNewLinks() {
      const { data } = await axios.get(`/dashboard/links?startDate=${startDateNewLinks}&endDate=${endDateNewLinks}&e_rede_credencial_id=${user.e_rede_credencial_id}`)
      setLinks(data);
    }
    loadNewLinks();
  }, [startDateNewLinks, endDateNewLinks, user.e_rede_credencial_id]);

  useEffect(() => {
    async function loadHistory() {
      const { data } = await axios.get(`/dashboard/company?e_rede_credencial_id=${user.e_rede_credencial_id}`);
      setProducts(data);
    }
    loadHistory();
  }, [user.e_rede_credencial_id]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__body">
              <h2>Dashboard</h2>
              <hr style={{ width: '100%' }} />
              <div className="row">
                <div className="col-md-6">
                  <Card>
                    <Card.Header className="d-flex flex-wrap justify-content-between align-items-start">
                      <div>
                        <h5 className="mb-0">
                          Novos Pedidos
                        </h5>
                        <div className="d-flex align-items-center mt-2">
                          De <Form.Control type="date" size="sm" className="ml-2 mr-2" value={startDateNewOrders} onChange={(e) => setStartDateNewOrders(e.target.value)}/> até <Form.Control type="date" size="sm" className="ml-2" value={endDateNewOrders} onChange={(e) => setEndDateNewOrders(e.target.value)}/>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body style={{ padding: '0px' }}>
                      <Table size="lg" responsive className="mb-0 mt-0" striped>
                        <thead>
                          <tr>
                            <th className="text-left">
                              Produto
                            </th>
                            <th>
                              Valor
                            </th>
                            <th className="text-right">
                              Data
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.data && orders.data.map(order => (
                            <tr key={order.order_id}>
                              <td>{order.product_name}</td>
                              <td>
                                R$ {normalizeCurrency(order.value_p || order.value_o)}
                              </td>
                              <td className="text-right">
                                {order.order_date ? format(parseISO(order.order_date), 'dd/MM/yyyy') : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-6">
                  <Card>
                    <Card.Header className="d-flex flex-wrap justify-content-between align-items-start">
                      <div>
                        <h5 className="mb-0">
                          Links de Pagamento
                        </h5>
                        <div className="d-flex align-items-center mt-2">
                          De <Form.Control type="date" size="sm" className="ml-2 mr-2" value={startDateNewLinks} onChange={(e) => setStartDateNewLinks(e.target.value)}/> até <Form.Control type="date" size="sm" className="ml-2" value={endDateNewLinks} onChange={(e) => setEndDateNewLinks(e.target.value)}/>
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body style={{ padding: '0px' }}>
                      <Table size="lg" responsive className="mb-0 mt-0" striped>
                        <thead>
                          <tr>
                            <th className="text-left">
                              Título
                            </th>
                            <th>
                              Valor
                            </th>
                            <th className="text-right">
                              Data
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {links.map(link => (
                            <tr key={link.id}>
                              <td>{link.product}</td>
                              <td>
                                R$ {normalizeCurrency(link.value)}
                              </td>
                              <td className="text-right">
                                {format(parseISO(link.created_at), 'dd/MM/yyyy')}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-6 mt-3">
                  <Card>
                    <Card.Header>
                      Relatório de Pedidos
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex align-items-center mt-2">
                        De <Form.Control type="date" value={orderStartDate} onChange={(e) => setOrderStartDate(e.target.value)} size="sm" className="ml-2 mr-2"/> até <Form.Control type="date" value={orderEndDate} onChange={(e) => setOrderEndDate(e.target.value)} size="sm" className="ml-2"/>
                      </div>
                      <Button disabled={orderStartDate === '' || orderEndDate === ''} onClick={() => {
                        window.open(`${process.env.REACT_APP_API_BASE_URL}/order-reports/export?start_order_date=${orderStartDate}&end_order_date=${orderEndDate}${user.e_rede_credencial_id ? `&e_rede_credencial_id=${user.e_rede_credencial_id}` : ''}`)
                      }} type="button" variant="outline-success" size="sm" className="mt-3">
                        Exportar
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-6 mt-3">
                  <Card>
                    <Card.Header>
                      Relatório de Links de Pagamento
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex align-items-center mt-2">
                        De <Form.Control type="date" value={orderPaymentLinkStartDate} onChange={(e) => setOrderPaymentLinkStartDate(e.target.value)} size="sm" className="ml-2 mr-2"/> até <Form.Control type="date" value={orderPaymentLinkEndDate} onChange={(e) => setOrderPaymentLinkEndDate(e.target.value)} size="sm" className="ml-2"/>
                      </div>
                      <Button disabled={orderPaymentLinkStartDate === '' || orderPaymentLinkEndDate === ''} onClick={() => {
                        window.open(`${process.env.REACT_APP_API_BASE_URL}/order-payment-link/export/${company_id}?startDate=${orderPaymentLinkStartDate}&endDate=${orderPaymentLinkEndDate}${user.e_rede_credencial_id ? `&e_rede_credencial_id=${user.e_rede_credencial_id}` : ''}`)
                      }} type="button" variant="outline-success" size="sm" className="mt-3">
                        Exportar
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-5 mt-3">
                  <Card>
                    <Card.Header>
                      Produtos Ativos
                    </Card.Header>
                    <Card.Body style={{ padding: '0px' }}>
                    <Table size="lg" responsive className="mb-0 mt-0">
                        <thead>
                          <tr>
                            <th className="text-left">
                              Produto
                            </th>
                            <th style={{ whiteSpace: 'nowrap' }}>
                              Valor Total
                            </th>
                            <th className="text-right" style={{ whiteSpace: 'nowrap' }}>
                              Total de Pedidos
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {products.map((p) => (
                                <tr
                                  key={p.id}
                                >
                                    <td>
                                      {p.name}
                                    </td>
                                    <td>
                                      R$ {normalizeCurrency(p.totalValue)}
                                    </td>
                                    <td className="text-right">
                                      {p.totalQuantity} Itens
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div>
                { /* <div className="col-md-7 mt-3">
                  <Card>
                    <Card.Header>
                      Produto mais vendido
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex align-items-start justify-content-between">
                        <img src={'https://checkout.api.tupi.io/files/1612802971427.png'} alt="Produto" style={{ width: '100%', maxWidth: '150px' }}/>
                        <div className="ml-3 text-right">
                          <h1>R$ 10.042,31</h1>
                          <h2>139 Vendas</h2>
                        </div>
                      </div>
                      <hr />
                      <Card.Title>
                        DEPILACAO A LASER - AXILA + VIRILHA - 5 SESSOES
                      </Card.Title>
                      <Card.Text>
                        DEPILACAO A LASER - AXILA + VIRILHA - 5 SESSOES
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card className="mt-3">
                    <Card.Header>
                      Totais de Link de Pagamento Aprovados
                    </Card.Header>
                    <Card.Body style={{ padding: '0px' }}>
                    <div style={{ padding: '15px' }} className="text-right">
                      <h2>R$ 10.042,31</h2>
                      <h3>139 Links</h3>
                    </div>
                    <hr className="mt-0 mb-0" style={{ padding: '0px' }}/>
                    <Table size="lg" responsive className="mb-0 mt-0" striped>
                        <thead>
                          <tr>
                            <th className="text-left">
                              Filial
                            </th>
                            <th>
                              Valor Total
                            </th>
                            <th className="text-right">
                              Quantidade Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              R$ 1,00
                            </td>
                            <td className="text-right">
                              1
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              R$ 1,00
                            </td>
                            <td className="text-right">
                              1
                            </td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>
                              R$ 1,00
                            </td>
                            <td className="text-right">
                              1
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div> */}
                {/* <div className="col-md-4">
                  <h5 className="text-center">Últimas ações</h5>
                  {histories.length > 0 &&
                    histories.map((h) => (
                      <div
                        key={h.id}
                        style={{
                          padding: "10px 15px",
                          background: "#f9f9f9",
                          marginTop: "10px",
                        }}
                      >
                        <strong style={{ display: "block" }}>{h.title}</strong>
                        <small>Por: {h.user?.name}</small>
                      </div>
                    ))}
                  <h5 className="text-center">...</h5>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
