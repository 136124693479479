import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
  makeStyles,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import ModalDetail from "./ModalDetail";

import { Form, Pagination } from "react-bootstrap";

import { normalizeCnpj, normalizeCurrency } from "../utils/normalize";

import { format, parseISO } from "date-fns";

import { toast } from "react-toastify";

export default function OrderIndex() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({});

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get("/me");
      setUser(data);
    }
    loadUser();
  }, []); // eslint-disable-line

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState({ active: false, item: {} });

  const [filials, setFilials] = useState([])

  const [filters, setFilters] = useState({
    e_rede_credencial_id: user.e_rede_credencial_id
  })

  useEffect(() => {
    async function loadPermissions() {
      try {
        const response = await axios.get(`/e-rede-credencials?page=1&perPage=100000`);
        setFilials(response.data.data);
      }catch(err) {
        //
      }
    }
    loadPermissions()
  }, [])

  async function loadOrders() {
    setLoading(true);
    try {
      const response = await axios.get(
        `/order-reports`, {
          params: {
            ...filters,
            e_rede_credencial_id: user.e_rede_credencial_id || filters.e_rede_credencial_id,
            page: page+1,
            perPage: rowsPerPage
          }
        }
      );
      setRows(response.data.data);
      setTotal(response.data.total);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        toast.error("Você não tem permissão para este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadOrders();
  }, []); // eslint-disable-line

  useEffect(() => {
    loadOrders();
  }, [page, rowsPerPage, user.e_rede_credencial_id]); // eslint-disable-line

  function handleNextPage() {
    const nextPage = page + 1;
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1;
    setPage(previousPage);
  }

  const headRows = [
    {
      id: "Produto",
      numeric: false,
      disablePadding: true,
      label: "Produto",
    },
    { id: "client", numeric: false, disablePadding: true, label: "Cliente" },
    { id: "value", numeric: false, disablePadding: true, label: "Valor" },
    { id: "status", numeric: false, disablePadding: true, label: "Status" },
    { id: "seller", numeric: false, disablePadding: true, label: "Vendedor" },
    { id: "origin", numeric: false, disablePadding: true, label: "Origem" },
    { id: "date", numeric: false, disablePadding: true, label: "Data" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.numeric ? "right" : "left"}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }
  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Pedidos</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/orders`}>
                Lista
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">

            </div>
          </div>
        </div>
      </div>
      <ModalDetail
        open={open.active}
        setOpen={() => setOpen({ active: false, item: {} })}
        item={open.item}
      />
      <Paper className={classes.paper}>
        <div className="d-flex align-items-end">
        <div className="row">
          {!user.e_rede_credencial_id && (
            <div className="col-md-3">
              <Form.Group className="m-0">
                <Form.Label>Filial</Form.Label>
                <Form.Control value={filters.e_rede_credencial_id} onChange={e => setFilters({
                  ...filters,
                  e_rede_credencial_id: e.target.value
                })} as="select">
                  <option value="">Todas</option>
                  {filials.map(f => (
                    <option value={f.id} key={f.id}>{f.filial} - {normalizeCnpj(f.cnpj)}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          )}
          <div className="col-md-2">
            <Form.Group className="m-0">
              <Form.Label>NSU</Form.Label>
              <Form.Control value={filters.nsu} onChange={e => setFilters({
                ...filters,
                nsu: e.target.value
              })}/>
            </Form.Group>
          </div>
          <div className="col-md-5">
            <Form.Group className="m-0">
              <Form.Label>Data</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control value={filters.start_order_date} onChange={e => setFilters({
                ...filters,
                start_order_date: e.target.value
              })} type="date"/>
                <span className="ml-2 mr-2">até</span>
                <Form.Control value={filters.end_order_date} onChange={e => setFilters({
                ...filters,
                end_order_date: e.target.value
              })} type="date"/>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-2">
            <Form.Group className="m-0">
              <Form.Label>Origem</Form.Label>
              <Form.Control value={filters.origin} onChange={e => setFilters({
                ...filters,
                origin: e.target.value
              })} as="select">
                <option value="">Todas</option>
                <option value="site">Site</option>
                <option value="checkout">Checkout</option>
                <option value="payment_link">Link de Pagamento</option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>
        <div className="d-flex ml-3">
          <button type="button" onClick={loadOrders} disabled={loading} className="btn btn-primary d-flex align-items-center">
            <i className="fas fa-filter"></i> Buscar
          </button>
          <a href={`${process.env.REACT_APP_API_BASE_URL}/order-reports/export?${Object.keys(filters).filter(k => filters[k]).map(k => `${k}=${filters[k]}`).join('&')}`} target="_blank" disabled={loading} className="btn btn-success d-flex align-items-center ml-2">
            Exportar
          </a>
        </div>
        </div>        
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows &&
                rows.length > 0 &&
                rows.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.order_id}>
                      <TableCell align="left">{row.product_name}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">
                        R$ {normalizeCurrency(row.value_p || row.value_o)}
                      </TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">{row.seller_id}</TableCell>
                      <TableCell align="left">{row.origin}</TableCell>
                      <TableCell align="left">
                        {row.order_date ? format(parseISO(row.order_date), "dd/MM/yyyy HH:mm") : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && <CircularProgress size={15} className="mr-2" />}
              <Pagination>
                {page > 0 && (
                  <>
                    <Pagination.First
                      onClick={() => setPage(0)}
                      disabled={page === 0 || loading}
                    />
                    <Pagination.Prev
                      onClick={() => handlePreviousPage()}
                      disabled={loading}
                    />
                  </>
                )}
                <Pagination.Item disabled>{page + 1}</Pagination.Item>
                {page < Math.ceil(total / rowsPerPage) - 1 && (
                  <>
                    <Pagination.Next
                      onClick={() => handleNextPage()}
                      disabled={loading}
                    />
                    <Pagination.Last
                      onClick={() =>
                        setPage(Math.ceil(total / rowsPerPage) - 1)
                      }
                      disabled={loading}
                    />
                  </>
                )}
              </Pagination>
            </div>
            <FormControl
              style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
              disabled={loading}
            >
              <InputLabel htmlFor="results_per_page">
                Result. p/Página
              </InputLabel>
              <Select
                id="results_per_page"
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
                disabled={loading}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}
