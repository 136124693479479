import React from "react";

import { Modal, Fade, Backdrop, makeStyles } from "@material-ui/core";

import { format, parseISO } from "date-fns";
import { normalizeCurrency } from "../utils/normalize";

export default function ModalDetail({ open, setOpen, item }) {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "700px",
      height: "auto",
      color: "#666",
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.fade}>
          <>
            <div className="row">
              <div className="col-md-11">
                <h2 id="transition-modal-title">Detalhamento</h2>
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            {Object.keys(item).length > 0 && (
              <div className="mt-4">
                <div className="d-flex justify-content-start align-items-center">
                  <i
                    className="fas fa-user mr-4"
                    style={{
                      background: "#e8f1eb",
                      padding: "15px",
                      borderRadius: "6px",
                      fontSize: "58px",
                    }}
                  ></i>
                  <div>
                    <h4>{item.name}</h4>
                    <div className="d-flex">
                      <p className="mb-1"><i className="fas fa-address-card mr-1"></i>{item.cpf}</p>
                      <p className="mb-1 ml-4"><i className="fas fa-envelope mr-1"></i>{item.email}</p>
                      <p className="mb-1 ml-4"><i className="fas fa-phone-square mr-1"></i>{item.phone}</p>
                    </div>
                    <p className="mb-1">
                      <i className="fas fa-truck mr-1"></i>
                      {item.address}, {item.number}{" "}
                      {item.complement && `- ${item.complement} `}, 
                      {item.neighborhood}, {item.city}, {item.uf}
                    </p>
                  </div>
                </div>
                <div
                  className="mt-4"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "15px",
                  }}
                >
                  <div
                    style={{
                      padding: "20px",
                      background: "#e8f1eb",
                      border: "1px solid #cee8d7",
                      borderRadius: "6px"
                    }}
                  >
                    <h4>Produtos</h4>
                    <hr />
                    {item.products.map(i => (
                      <div style={{ padding: '10px', background: 'rgba(0,0,0,.05)' }} className="mt-2" key={i.id}>
                        <h5 className="mb-1">{i.product.name}</h5>
                        <h6 className="mb-1">Quantidade Escolhida: {i.quantity}</h6>
                        <p className="mb-1">{i.product.description}</p>
                        <p className="mb-1 mt-2">
                          Valor: R$ {normalizeCurrency(i.product.value)}
                        </p>
                        <p className="mb-1">
                          Filial: {i.product.credencial.filial}
                        </p>
                      </div>
                    ))}
                    
                    
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      background: "#e8f1eb",
                      border: "1px solid #cee8d7",
                      borderRadius: "6px"
                    }}
                  >
                    <h4>Dados da Transação</h4>
                    <hr />
                    <p className="mb-1">ID: {item.reference}</p>
                    <p className="mb-1">
                      Status: <strong>{item.status}</strong>
                    </p>
                    <p className="mb-1">
                      NSU: <strong>{item.nsu}</strong>
                    </p>
                    <p className="mb-1">
                      Parcelas: <strong>{item.installments}x</strong>
                    </p>
                    <p className="mb-1">
                      Valor Pago: R$ {normalizeCurrency(item.value)}
                    </p>
                    <p className="mb-1">
                      Data: {format(parseISO(item.order_date), "dd/MM/yyyy")}
                    </p>
                    <p className="mb-1">
                      Vendedor: <strong>{item.seller ? item.seller.name : '----'}</strong>
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-secondary kt-subheader__btn-options ml-2"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Fechar
              </button>
            </div>
          </>
        </div>
      </Fade>
    </Modal>
  );
}
